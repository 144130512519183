
import { Options, Vue } from 'vue-class-component';
import AdminNavbar from "@/components/adminNavbar.vue";

@Options({
    components: {
        AdminNavbar
    }
})
export default class AdminLayout extends Vue {

    created() {

    }

}
